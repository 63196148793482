<template>
  <VueFinalModal name="socials" v-bind="$attrs" :ssr="false" :value="false" classes="modal-container" content-class="modal-content" @before-open="onBeforeOpen" @closed="opened = false">
    <div id="timeModal" class="box-modal">
      <div class="box-modal_close arcticmodal-close" @click.prevent="$vfm.hide('socials')">
        <img class="close" src="/icons/close.svg" alt="" />
      </div>
      <div class="title">{{ $t("modal.socials.title") }}</div>
      <div class="robot-box">
        <div class="alert">{{ $t("modal.socials.desc") }}</div>
        <a v-if="!hideVk" target="_blank" :href="`https://vk.com/public${$config.public.VK_GROUP}`" class="btn">
          <FontIcon icon="vkcolored" size="17" class="mr5" />
          {{ $t("modal.socials.subscribe") }}
        </a>
        <a target="_blank" :href="`https://t.me/${$config.public.TG_GROUP}`" class="btn">
          <FontIcon icon="telegram" size="15" class="mr5" />
          {{ $t("modal.socials.subscribe") }}
        </a>
        <a target="_blank" :href="`https://t.me/${$config.public.TG_RESERVE}`" class="btn">
          <FontIcon icon="telegram" size="15" class="mr5" />
          {{ $t("modal.socials.subscribe") }}
        </a>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import FontIcon from "../FontIcon.vue";

export default {
  components: { VueFinalModal, FontIcon },
  setup(props, { emit }) {
    const opened = ref(false);
    const hideVk = ref(false);

    const onBeforeOpen = (e) => {
      opened.value = true;
      hideVk.value = false;
      if (e.ref?.params?.value?.hideVk) {
        hideVk.value = true;
      }
    };

    return {
      opened,
      hideVk,
      onBeforeOpen,
    };
  },
};
</script>
